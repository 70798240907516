<template>
  <div>
    <b-card>
      <div class="card-toolbar">
        <button
          type="reset"
          class="btn btn-success mr-2"
          @click="create()"
          ref="kt_save_changes"
          v-if="!editStatus"
        >
          Add New Status
        </button>
      </div>
      <div v-if="!editStatus">
        <b-table
          striped
          hover
          class='clickabletd'
          sort-icon-left
          :items="currentStatusesData"
          :fields="statusFields"
          :tbody-tr-class="rowClass"          
          @row-clicked="rowClickHandler"
        >
          <template #cell(actions)="data">
            <v-icon small v-on:click="deleteRecord(data.item.id,data.item.active)" v-if="data.item.active && (data.item.score != 100 && data.item.score != 0)"
              >mdi-delete</v-icon
            >
            <v-icon small v-on:click="deleteRecord(data.item.id,data.item.active)" v-if="!data.item.active"
              >mdi-restart</v-icon
            >
          </template>
          <template #cell(bgcolor)="data">
            <span 
              v-bind:style="{ background: data.item.bgcolor }"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          </template>


        </b-table>
      </div>

      <div v-if="editStatus">
        <KTStatusInformation
          :record="editStatusRecord"
          @canceled="editStatus = false"
          @finished="finished()"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import {
  GET_STATUSES,
  DELETE_STATUS_DETAILS,
} from "@/core/services/store/statuses.module";

import KTStatusInformation from "@/view/pages/management/statuses/StatusInformation";

export default {
  name: "workplans",
  components: {
    KTStatusInformation,
  },
  data() {
    return {
      editStatus: false,
      editStatusRecord: [],

      statusFields: [
        { key: "name", sortable: true },
        { key: "score", sortable: true , label: "Score %"},
        { key: "bgcolor", label: "Color"},
        { key: "actions", label: "" },
      ],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Manage Status" }]);
    this.getData();
  },
  methods: {
    getData() {
      this.$store.dispatch(GET_STATUSES);
    },

    finished() {
      this.editStatus = false;
      this.editStatusRecord = [];
      setTimeout(() => {
        this.getData();
      }, 500);
    },
    rowClickHandler(record, index) {
      console.log(record, index); // This will be the item data for the row
      if (record.score != 100 && record.score != 0) {
        this.editStatusRecord = record;
        this.editStatus = true;
      }
    },

    create() {
      this.editStatusRecord = {
        id: -1,
        name: "",
        score: 0,
        bgcolor: "#eeeeee",
        color: "#aaaaaa",
        order: 0,
      };
      this.$nextTick(() => {
        this.editStatus = true;
      });      
    },
    rowClass(item, type) {
      console.log(type,item)
        if (item.active != true) { 
          return 'text-deleted'
        } else {
          return ''
        } 
    },        
    deleteRecord(id,active) {
      var text = "Do you really want to delete?";
      if (!active ) {text = "Do you really want to restore?"; }
      if (confirm(text)) {
        this.editStatus = false;
        this.$store.dispatch(DELETE_STATUS_DETAILS, {
          id,
        });
        setTimeout(() => {
          this.getData();
        }, 500);
      }
    },
  },
  computed: {
    ...mapGetters(["currentStatusesData"]),
    getName() {
      var result = "";
      if (this.currentFramework.framework) {
        result = this.currentFramework.framework.name;
      }
      return result;
    },
    getFrameworkHeaders() {
      var results = [];
      if (this.currentFramework.framework_items) {
        this.currentFramework.framework_items.forEach((framework_item) => {
          var targetListResult = results.filter(function(o) {
            return o.function_code == framework_item.function_code;
          });
          if (targetListResult.length === 0) {
            results.push({
              function_code: framework_item.function_code,
              function: framework_item.function,
            });
          }
        });
      }
      return results;
    },
  },
};
</script>
