<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3
          class="card-label font-weight-bolder text-dark"
          v-if="record.id != -1"
        >
          Edit Status
        </h3>
        <h3
          class="card-label font-weight-bolder text-dark"
          v-if="record.id == -1"
        >
          Create Status
        </h3>
      </div>
      <div class="card-toolbar">
        <button
          type="reset"
          class="btn btn-success mr-2"
          @click="save()"
          ref="kt_save_changes"
        >
          Save Changes
        </button>
        <button type="reset" class="btn btn-secondary" @click="cancel()">
          Cancel
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form" v-if="statusRecord">
      <!--begin::Body-->
      <div class="card-body">
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">Name</label>
          <div class="col-lg-9 col-xl-6">
            <input
              ref="name"
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model.trim="$v.statusRecord.name.$model"
            />
            <div class="error" v-if="!$v.statusRecord.name.required">
              <i>Name is required</i>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Score %</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              ref="score"
              class="form-control form-control-lg form-control-solid"
              type="number"
              v-model.trim="$v.statusRecord.score.$model"
            />
            <div class="error" v-if="!$v.statusRecord.score.required">
              <i>Score is required</i>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            > Color</label>
          <div class="col-lg-9 col-xl-6">
            <compact-picker v-model="color" v-bind:change-color="setColor"/>
          </div>
        </div>        
      </div>
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  UPDATE_STATUS_DETAILS,
  CREATE_STATUS_DETAILS,
} from "@/core/services/store/statuses.module";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { Compact } from 'vue-color';

export default {
  mixins: [validationMixin],
  name: "PersonalInformation",
  props: {
    record: Object,
  },
  components: {
    'compact-picker': Compact
  },  
  data() {
    return {
      statusRecord: this.record,
      statusSettings: this.record.settings,
      color: this.record.bgcolor, 
    };
  },
  validations: {
    statusRecord: {
      name: {
        required,
      },
      score: {
        required,
      },
    },
  },
  mounted() {
    console.log('record',this.statusRecord);
  },
  methods: {
    save() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        var name = this.$refs.name.value;
        var score = this.$refs.score.value;
        var id = this.statusRecord.id;
        var bgcolor = this.statusRecord.bgcolor;

        // set spinner to submit button
        const submitButton = this.$refs["kt_save_changes"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        if (id == -1) {
          // send update request
          this.$store
            .dispatch(CREATE_STATUS_DETAILS, {
              id,
              name,
              score,
              bgcolor
            })
            .then(() => {
              this.$emit("finished");
            });
        } else {
          // send update request
          this.$store
            .dispatch(UPDATE_STATUS_DETAILS, {
              id,
              name,
              score,
              bgcolor
            })
            .then(() => {
              this.$emit("finished");
            });
        }

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }
    },
    cancel() {
      this.$emit("canceled");
    },
    setColor(val) {
      console.log('val');
      console.log(val);
    }
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  watch: {
    record: function(newVal, oldVal) {
      // watch it
      console.log("Prop changed: ", newVal, " | was: ", oldVal);
    },
    color: function(newVal, oldVal) {
      console.log("Prop changed: ", newVal, " | was: ", oldVal);
      if (newVal.hex) { 
        this.statusRecord.bgcolor = newVal.hex;
      }
    },
  },
};
</script>
<style>
.error i {
  color: #cc0000;
}
</style>
